import { ERROR_SERVICES, store, useEnvironment, useFeature } from '@sewan/core';
import { configRoutes } from '../constants';

export const useFilteredConfigRoutes = (isMenu) => {
    const {
        mobile: hasMobile,
        landline: hasLandline,
        advancedMobile: hasAdvancedMobile,
        hostedTelephony: hasHostedTelephony,
        eSimCard: hasESimCard,
        webex: hasWebex,
    } = store.availableServices.getAvailableServices();

    const errorsFromInitServices = store.availableServices.getErrorsServices();
    const hasErrorMobile = errorsFromInitServices.includes(ERROR_SERVICES.MOBILE_LINE_LIMITED_INFORMATION);
    const errorHostedTelephony = errorsFromInitServices.includes(ERROR_SERVICES.HOSTED_TELEPHONY_ACCOUNT);

    const [hostedTelephonyFeatureIsEnabled] = useFeature('hierarchy');

    const disabledModules = store.customization.getDisabledModules();
    const noPhone = (!hasMobile && !hasErrorMobile) && (!hasLandline && !errorHostedTelephony);
    const { isProductionEnvironment } = useEnvironment();
    const hasHTandFeatureFlag = hasHostedTelephony && hostedTelephonyFeatureIsEnabled;
    const mobileOnly = hasMobile && !hasLandline && !hasAdvancedMobile && !hasHTandFeatureFlag;

    const filteredConfigRoutes = configRoutes.filter((item) => !(
        disabledModules.includes(item.module)
        || (isMenu && !item.availableInMenu)
        || (isProductionEnvironment && item.mustbeHidden.production)
        || ((item.mustbeHidden.mobileOnly && mobileOnly) && !(item.hasESimCard && hasESimCard))
        || ((item.mustbeHidden.noPhone && noPhone) && !(hasHTandFeatureFlag && !item.mustbeHidden.hostedTelephonyOnly))
        || ((item.mustbeHidden.webex && hasWebex && hasHTandFeatureFlag))
    ));

    return {
        filteredConfigRoutes,
        noPhone       : noPhone && !hasHostedTelephony,
        hasWebex,
        hasFeatureFlag: hostedTelephonyFeatureIsEnabled,
        hasHostedTelephony,
        hasAdvancedMobile,
        hasMobile,
    };
};
