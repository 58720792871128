import { props as coreProps } from '@ui-kit/core';
import PropTypes from 'prop-types';
import React from 'react';

import { SWrapper, SContainer, SContent, SSideBar } from './styled';

const { default: props } = coreProps;

/**
 *
 * This component is split into three sections (Header, Content, Sidebar).
 *
 * Only provides an element distribution, the styles must be provided by the developer
 */
const MainLayout = ({ header, content, sidebar, className }) => (
    <SWrapper className={className}>
        {header && <div>{header}</div>}
        <SContainer>
            {sidebar && <SSideBar>{sidebar}</SSideBar>}
            {content && <SContent>{content}</SContent>}
        </SContainer>
    </SWrapper>
);

MainLayout.defaultProps = {
    ...props.styledComponents.className.defaultProps,
    header : undefined,
    sidebar: undefined,
};

MainLayout.propTypes = {
    ...props.styledComponents.className.types,
    content: PropTypes.node.isRequired,
    header : PropTypes.node,
    sidebar: PropTypes.node,
};

export default MainLayout;
