import { store, localStorage } from '@sewan/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import defaultFavicon from '#assets/images/favicon.ico';

const Favicon = ({ children }) => {
    const favicon = store.customization.getFavicon();
    const { favicon : faviconFromStorage } = localStorage.getCusto();

    const previousFaviconLink = document.querySelector('link[rel=icon]');
    if (previousFaviconLink) {
        document.querySelector('head').removeChild(previousFaviconLink);
    }

    return (
        <>
            <Helmet>
                <link rel="icon" href={faviconFromStorage || favicon?.url || defaultFavicon} />
            </Helmet>
            {children}
        </>
    );
};

Favicon.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Favicon;
