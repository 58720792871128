export const SELFCARE_TESTS_URLS = {
    dev    : 'https://foundation-dev.priv.sewan.fr',
    staging: 'https://sophia-staging.sewan.fr',
};

const DOMAINS_EXTENSIONS = [
    'com',
    'fr',
    'eu',
    'ch',
    'be',
    'net',
    'org',
    'lu',
    'de',
    'info',
    'at',
    'it',
    'es',
    'tel',
];

export const LOCALHOST = 'localhost';

const SELFCARE_GO_PROD_HOSTNAMES = DOMAINS_EXTENSIONS.map((extension) => `myphoneboard.${extension}`);
const SELFCARE_GO_STAGING_HOSTNAME = 'sophia-staging.sewan.fr';

export const SELFCARE_PROD_URLS = SELFCARE_GO_PROD_HOSTNAMES.map((hostname) => `https://${hostname}`);

export const SELFCARE_LIVE_URLS = {
    production: {
        fr: 'https://montableaudebord.fr',
        es: 'https://miarea.es',
        be: 'https://myselfcare.be',
    },
    staging: 'https://selfcare-staging.priv.sewan.fr',
};

const SELFCARE_LIVE_HOSTNAMES = [
    ...Object.values(SELFCARE_LIVE_URLS.production).map((url) => new URL(url).hostname),
    new URL(SELFCARE_LIVE_URLS.staging).hostname,
];

export const APPLICATION_HOSTNAMES = [
    ...SELFCARE_GO_PROD_HOSTNAMES,
    SELFCARE_GO_STAGING_HOSTNAME,
    ...SELFCARE_LIVE_HOSTNAMES,
    LOCALHOST,
];
