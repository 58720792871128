import TextLink from '@ui-kit/textlink';
import styled, { th } from '@xstyled/styled-components';
import React from 'react';

export const SDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0 0 12px;
    width: calc(100% - 14px);
    padding: 0 0 16px 16px;
    border-radius: 4px;
    border: 1px solid #D9DEE1;

    @media (min-width: ${th('breakpoints.lg')}) {
        width: 252px;
    }
`;

export const STextLink = styled((props) => <TextLink {...props} />)`
    margin: 4px 0px;
    font-size: ${th('newTheme.fontSizes.text.sm')};
    color: ${th('newTheme.colors.greys.700')};
    min-width: calc(100% - 10px);

    @media (min-width: ${th('breakpoints.lg')}) {
        max-width: 252px;
        min-width: unset;
    }

    span:nth-child(2) {
        max-width: calc(100% - 8px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (min-width: ${th('breakpoints.lg')}) {
            max-width: calc(252px - 50px);
        }
    }
`;
