import { useTranslation, store, PhoneNumber } from '@sewan/core';
import * as icons from '@ui-kit/icon';
import Typography from '@ui-kit/typography';
import React from 'react';
import { TEST_IDS } from '../../../../testIds';
import { SDiv, STextLink } from './styles';

const { MailIcon, PhoneIcon, Globe1Icon } = icons;

const settings = {
    email: {
        prefix: 'mailto:',
        target: '_self',
        icon  : <MailIcon />,
    },
    phoneNumber: {
        prefix: 'tel:',
        target: '_self',
        icon  : <PhoneIcon />,
    },
    url: {
        target: '_blank',
        icon  : <Globe1Icon />,
    },
};

const sortLinks = (entries) => {
    const order = {
        email      : 0,
        phoneNumber: 1,
        url        : 2,
    };

    entries.sort(([keyA], [keyB]) => order[keyA] - order[keyB]);

    return entries;
};

const getOnlyDomainInUrl = (url) => {
    const prefixes = ['https://www.', 'http://www.', 'https://', 'http://'];

    let domainWithoutProtocol = url;
    prefixes.forEach((prefix) => {
        if (domainWithoutProtocol.startsWith(prefix)) {
            domainWithoutProtocol = domainWithoutProtocol.slice(prefix.length);
        }
    });

    const domainOnly = domainWithoutProtocol.split('/')[0];

    return domainOnly;
};

const Contact = () => {
    const { t } = useTranslation('common');
    const contact = store.customization.getContact();
    const filteredContact = Object.entries(contact).filter((entry) => Boolean(entry[1]));
    const sortedLinks = sortLinks(filteredContact);

    if (sortedLinks.length === 0) {
        return '';
    }

    return (
        <SDiv>
            <Typography
                as="p"
                color="greys.800"
                size="md"
                weight="medium"
                gutterBottom="1,5"
                gutterTop={2}
            >
                {t('nav.contact.oneQuestion')}
            </Typography>
            {sortedLinks.map(([key, value]) => {
                const { prefix, icon, target } = settings[key];

                return (
                    <STextLink
                        key={value}
                        href={prefix ? `${prefix}${value}` : value}
                        iconLeft={icon}
                        target={target}
                        variant="secondary"
                        testIds={{
                            container: `${TEST_IDS.textLink.container}-${value}`,
                            leftIcon : `${TEST_IDS.textLink.leftIcon}-${value}`,
                        }}
                        weight="regular"
                    >
                        {key === 'phoneNumber' ? <PhoneNumber phoneNumber={value} /> : null}
                        {key === 'url' ? getOnlyDomainInUrl(value) : null}
                        {key === 'email' ? value : null}
                    </STextLink>
                );
            })}
        </SDiv>
    );
};

export default Contact;
