import { theme as coreTheme } from '@ui-kit/core';
import { defineColor } from '@ui-kit/themeprovider';

import styled, { th } from '@xstyled/styled-components';

const { default: theme } = coreTheme;

export const SWrapper = styled.div`
    height: 100%;
    @media (min-width: ${th('breakpoints.lg')}) {
        width: 264px;
    }
`;

export const StyledOverlay = styled.div`
    position : fixed;
    opacity: ${({ isOpen }) => (isOpen ? 0.6 : 0)};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    transition: visibility 0s, opacity 0.35s ease-in-out;
    background-color: #021118;
    width: 100%;
    height: 100%;
    z-index: 4;
    top: 0;

    @media (min-width: ${th('breakpoints.lg')}) {
        display: none;
    }
`;

export const StyledContainer = styled.nav`
    transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-264px)')};
    pointer-events: ${({ isOpen }) => (isOpen ? 'inherit' : 'none')};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    transition: opacity 0.35s, transform 0.35s ease-in-out;
    background-color: ${theme.newTheme.colors.greys[100]};
    position : fixed;
    padding-right: 12px;
    padding-top: md;
    width: calc(100% - 60px);
    height: 100%;
    z-index: 5;
    top: 0;

    @media (min-width: ${th('breakpoints.lg')}) {
        transform: translateX(0);
        transition: none;
        padding-right: 0;
        display: initial;
        width: 264px;
        opacity: 1;
        pointer-events: inherit;
        top: unset;
        margin-top: md;
        padding-top: 0;
    }
`;

export const SMainMenuWrapper = styled.div`
    display: initial;

    @media (max-width: ${th('breakpoints.lg')}) {
        li {
            cursor: default;
        }
    } 
`;

export const GridThirdPartyMenuItem = styled.div`
    min-width: 253px;
    min-height: 84px;
    margin: 20px 0 0 ${th('newTheme.space.1,5')};
    display: grid;
    grid-template-areas:
        "logo service"
        "logo title"
        "logo description";
    grid-template-columns: fit-content(48px) 1fr;
    border-radius: ${th('newTheme.space.0,5')};
    padding: ${th('newTheme.space.2')};
    column-gap: ${th('newTheme.space.1,5')};
    cursor: pointer;

    background-color: ${(props) => (props.isActive ? defineColor('lt.100', 'lt2') : '#FFFFFF')};
    border: 1px solid ${(props) => (props.isActive ? defineColor('primary', 'main') : th('newTheme.colors.greys.200'))};
`;

export const GridThirdPartyMenuItemLogo = styled.div`
    grid-area: logo;
    display: flex;
    align-items: center;
    justify-content: center;
`;
