import { useTranslation, getBase64FromUrl, store, DEFAULT_PWA_NAMES } from '@sewan/core';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import defaultIconLg from '#assets/images/icon_lg.png';
import defaultIconMd from '#assets/images/icon_md.png';
import defaultIconSm from '#assets/images/icon_sm.png';
import defaultIconXl from '#assets/images/icon_xl.png';

const PwaProvider = ({ children }) => {
    const { t, i18n: { language } } = useTranslation('common');
    const { origin } = window.location;
    const { icon, name: fullName, shortName } = store.customization.getPwa();

    const customIconSm = icon?.find(({ name }) => name === 'sm')?.url;
    const customIconMd = icon?.find(({ name }) => name === 'md')?.url;
    const customIconLg = icon?.find(({ name }) => name === 'lg')?.url;
    const customIconXl = icon?.find(({ name }) => name === 'xl')?.url;

    const iconSm = customIconSm || defaultIconSm;
    const iconMd = customIconMd || defaultIconMd;
    const iconLg = customIconLg || defaultIconLg;
    const iconXl = customIconXl || defaultIconXl;

    let path = '/foundation/selfcare/';
    if (process.env.NODE_ENV === 'development') {
        path = '/selfcare/';
    }

    const baseUrl = `${origin}${path}`;
    const serviceWorkerPath = process.env.NODE_ENV === 'development' ? 'sw.js' : 'service-worker';
    const serviceWorkerUrl = `${baseUrl}${serviceWorkerPath}`;

    useEffect(() => {
        (async () => {
            const iconBase64Sm = await getBase64FromUrl(iconSm);
            const iconBase64Md = await getBase64FromUrl(iconMd);
            const iconBase64Lg = await getBase64FromUrl(iconLg);
            const iconBase64Xl = await getBase64FromUrl(iconXl);

            const manifest = {
                short_name      : shortName || DEFAULT_PWA_NAMES.SHORT_NAME,
                name            : fullName || DEFAULT_PWA_NAMES.FULL_NAME,
                description     : t('pwa.description'),
                theme_color     : '#ffffff',
                background_color: '#ffffff',
                display         : 'standalone',
                scope           : baseUrl,
                orientation     : 'portrait',
                icons           : [
                    {
                        src  : iconBase64Sm,
                        type : 'image/png',
                        sizes: '48x48',
                    },
                    {
                        src  : iconBase64Md,
                        type : 'image/png',
                        sizes: '96x96',
                    },
                    {
                        src  : iconBase64Lg,
                        type : 'image/png',
                        sizes: '192x192',
                    },
                    {
                        src  : iconBase64Xl,
                        type : 'image/png',
                        sizes: '512x512',
                    },
                ],
                start_url: baseUrl,
            };

            const manifestContent = encodeURIComponent(JSON.stringify(manifest));
            const url = `data:application/manifest+json,${manifestContent}`;
            const manifestLink = document.createElement('link');
            manifestLink.setAttribute('rel', 'manifest');
            manifestLink.setAttribute('href', url);
            document.querySelector('head').appendChild(manifestLink);
        })();

        return () => {
            const previousManifestLink = document.querySelector('link[rel=manifest]');
            if (previousManifestLink) {
                document.querySelector('head').removeChild(previousManifestLink);
            }
        };
    }, [
        language,
        t,
        baseUrl,
        shortName,
        fullName,
        iconSm,
        iconMd,
        iconLg,
        iconXl,
    ]);

    useEffect(() => {
        (async () => {
            const iosIcon = await getBase64FromUrl(iconLg);
            const iosIconLink = document.createElement('link');
            iosIconLink.setAttribute('rel', 'apple-touch-icon');
            iosIconLink.setAttribute('href', iosIcon);
            iosIconLink.setAttribute('sizes', '192x192');
            document.querySelector('head').appendChild(iosIconLink);
        })();
    }, [iconLg]);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register(serviceWorkerUrl, { scope: path });
        }
    }, [path, serviceWorkerUrl]);

    return children;
};

PwaProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PwaProvider;
