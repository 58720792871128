import { useTranslation, store } from '@sewan/core';
import { Responsive } from '@sewan-selfcare/responsive-system';
import * as Icons from '@ui-kit/icon';
import React from 'react';

import {
    SLeftSide,
    STitle,
    SWrapper,
    SBurgerButton,
    SSelfcareLogo,
    SMobileHeaderWrapper,
    SIconSelfcare,
} from './styles';
import AvatarMenu from './subcomponents/AvatarMenu';
import SelfcareImg from '../../assets/images/Selfcare-desktop.png';

const { BurgerIcon } = Icons;

const Header = () => {
    const { t } = useTranslation('common');
    const showBurger = store.burger.getShowBurger();
    const hasBurger = store.burger.getHasBurger();
    const customLogo = store.customization.getHeaderLogo();
    const logoSrc = customLogo?.url || SelfcareImg;

    const toggle = (setOpen) => {
        if (setOpen) {
            store.burger.setShowBurger(false);
        } else {
            store.burger.setShowBurger(true);
        }
    };

    const desktopHeader = (
        <>
            <STitle>
                <SSelfcareLogo>
                    <SIconSelfcare alt="Selfcare logo" src={logoSrc} />
                </SSelfcareLogo>
                {!customLogo && (<span>{t('header.title')}</span>)}
            </STitle>
            <SLeftSide>
                <AvatarMenu />
            </SLeftSide>
        </>
    );

    const mobileHeader = (
        <SMobileHeaderWrapper hasBurger={hasBurger}>
            <SBurgerButton>
                { hasBurger && (
                    <BurgerIcon data-testid="burger_test" onClick={() => toggle(showBurger)} />
                )}
            </SBurgerButton>
            <SSelfcareLogo>
                <SIconSelfcare alt="Selfcare logo" src={logoSrc} />
            </SSelfcareLogo>
            <SLeftSide>
                <AvatarMenu />
            </SLeftSide>
        </SMobileHeaderWrapper>
    );

    return (
        <SWrapper>
            <Responsive components={[mobileHeader, mobileHeader, mobileHeader, desktopHeader]} />
        </SWrapper>
    );
};

export default Header;
