import styled from '@xstyled/styled-components';

export const SWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: relative;
`;

export const SContainer = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
`;

export const SContent = styled.div`
    flex: 1;
    overflow: auto;
`;

export const SSideBar = styled.aside`
    width: fit-content;
`;
