export const DATE_FORMAT = Object.freeze({
    monthYear         : 'MMMM yyyy',
    dayMonth          : 'dd MMMM',
    dayOfWeek         : 'EEEE',
    month             : 'LLLL',
    digitsMonth       : 'M',
    hourMinutes       : 'HH:mm',
    hourMinutesSeconds: 'HH:mm:ss',
    yearMonthDay      : 'yyyy-MM-dd',
    ISO_8601          : {
        withZ: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
    },
    FR: {
        DayOfTheWeekDayMonthYear: 'EEEE d MMMM yyyy',
        DayOfTheWeekDayMonth    : 'EEEE d MMMM',
    },
    litteralDayMonthYear: 'dd LLL',
    dayMonthYear        : {
        FR: 'dd/MM/yyyy',
        GB: 'dd/MM/yyyy',
        ES: 'dd/MM/yyyy',
        DE: 'yyyy-mm-dd',
        BE: 'dd.mm.yyyy',
    },
});
