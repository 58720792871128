export const ERROR_TYPE = Object.freeze({
    notFound        : '404',
    wip             : 'WIP',
    technicalProblem: 'TECHNICAL_PROBLEM',
});

export const ERROR_ACTION = Object.freeze({
    redirect: 'REDIRECT',
    reload  : 'RELOAD',
});

export const ERROR_SERVICES = Object.freeze({
    MOBILE_LINE_LIMITED_INFORMATION: 'getMobileLinesLimitedInformation',
    MOBILE_LINE                    : 'getMobileLine',
    VOICE_ACCOUNT_BY_IDENTITY_ID   : 'getVoiceAccountIdByIdentityId',
    HOSTED_TELEPHONY_ACCOUNT       : 'getHostedTelephonyAccounts',
    WEBEX_ACCOUNT                  : 'getWebexAccounts',
});
