import { eachDayOfInterval, eachMonthOfInterval, addDays } from 'date-fns';
import { enGB, fr, es, de, nl } from 'date-fns/esm/locale';

export const MAPPER_LANGUAGE_LOCALE = Object.freeze({
    fr,
    es,
    de,
    nl,
    enGB,
    'en-GB': enGB,
    'fr-FR': fr,
    'es-ES': es,
    'de-DE': de,
    'nl-NL': nl,
});

export const months = eachMonthOfInterval({
    start: new Date(2022, 0, 1),
    end  : new Date(2022, 11, 31),
});

export const weekDays = eachDayOfInterval({ start: new Date(2022, 4, 1), end: addDays(new Date(2022, 4, 1), 6) });

export const dateNow = new Date(Date.now());
