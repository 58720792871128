import styled, { th } from '@xstyled/styled-components';

export const SBody = styled.div`
    overflow-x: hidden;
    padding: 16px 0px 0;
    height: 100%;

    @media (min-width: ${th('breakpoints.lg')}) {
        padding: default 48px;
    }
`;
