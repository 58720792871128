import { Title, keycloakService } from '@sewan/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { configRoutes, configNoAuthRoutes } from '../../constants';
import { getRoutesInfoFromPathnameAndConfig } from './utils';

const PageTitle = ({ children }) => {
    const { pathname } = useLocation();

    const title = keycloakService.getToken()
        ? getRoutesInfoFromPathnameAndConfig(pathname, configRoutes)?.transKey
        : getRoutesInfoFromPathnameAndConfig(pathname, configNoAuthRoutes)?.transKey;

    return (
        <>
            <Title section={title} />
            {children}
        </>
    );
};

PageTitle.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PageTitle;
