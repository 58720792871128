export const LANGUAGE_CODES = Object.freeze({
    deDE: 'de-DE',
    enGB: 'en-GB',
    esES: 'es-ES',
    frFR: 'fr-FR',
    nlNL: 'nl-NL',
});

export const LANGUAGES = Object.freeze({
    [LANGUAGE_CODES.deDE]: 'Deutsch',
    [LANGUAGE_CODES.enGB]: 'English',
    [LANGUAGE_CODES.esES]: 'Español',
    [LANGUAGE_CODES.frFR]: 'Français',
    [LANGUAGE_CODES.nlNL]: 'Nederlands',
});

export const COUNTRIES_ISO_3166 = Object.freeze({
    DE: 'DE',
    GB: 'GB',
    ES: 'ES',
    BE: 'BE',
    NL: 'NL',
    FR: 'FR',
});

export const PHONE_INPUT_COUNTRIES = Object.freeze({
    [COUNTRIES_ISO_3166.DE]: 'DE',
    [COUNTRIES_ISO_3166.GB]: 'GB',
    [COUNTRIES_ISO_3166.ES]: 'ES',
    [COUNTRIES_ISO_3166.BE]: 'BE',
    [COUNTRIES_ISO_3166.NL]: 'NL',
    [COUNTRIES_ISO_3166.FR]: 'FR',
});

export const DATE_FORMAT_BY_LANGUAGE = Object.freeze({
    [COUNTRIES_ISO_3166.DE]: 'yyyy-MM-dd',
    [COUNTRIES_ISO_3166.GB]: 'dd/MM/yyyy',
    [COUNTRIES_ISO_3166.ES]: 'dd/MM/yyyy',
    [COUNTRIES_ISO_3166.FR]: 'dd/MM/yyyy',
    [COUNTRIES_ISO_3166.BE]: 'dd.MM.yyyy',
    default                : 'dd/MM/yyyy',
});

export const DEFAULT_LANGUAGE_CODE = LANGUAGE_CODES.frFR;

export const I18N_NAMESPACES = [
    'common', // IMPORTANT: Always keep this as the first one, as it will be the default one
];
