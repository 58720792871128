import PropTypes from 'prop-types';

export const getRoutesInfoFromPathnameAndConfig = (pathname, config) => config.find(({ path }) => {
    const cleanPathname = pathname[0] === '/' ? pathname.substring(1) : pathname;

    return cleanPathname === path;
});

getRoutesInfoFromPathnameAndConfig.propTypes = {
    config  : PropTypes.arrayOf.isRequired,
    pathname: PropTypes.string.isRequired,
};
