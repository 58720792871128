export const SORT_TYPE = Object.freeze({
    firstName: 'firstName',
    name     : 'name',
});

export const CONTACT_TYPE = Object.freeze({
    external: 'EXTERNAL',
    internal: 'INTERNAL',
    personal: 'PERSONAL',
});

export const CONTACTS_DEFAULT_OPTIONS = Object.freeze({
    sortBy: SORT_TYPE.firstName,
    filter: '',
    search: '',
});

export const DEFAULT_PAGINATION = Object.freeze({
    page : 1,
    limit: 100,
});
