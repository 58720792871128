export const PHONE_CATEGORIES = {
    landline: 'LANDLINE',
    mobile  : 'MOBILE',
};

export const PHONE_TYPES = {
    landline: 'CENTREX',
    mobile  : 'AML',
};

export const PHONE_AVAILABLE_SERVICES = {
    landline: 'HOSTED_TELEPHONY',
    mobile  : 'MOBILE',
};

export const SIM_CARD = {
    esim : 'esim',
    basic: 'basic',
};

export const PLATFORM_TYPE = Object.freeze({
    bwprod: 'bwprod',
});

export const PHONE_CALL_CATEGORIES = {
    in    : 'IN',
    out   : 'OUT',
    missed: 'MISSED',
};

export const E64_TYPES = {
    landline: ['FIXED_LINE', 'PREMIUM_RATE', 'VOIP', 'SHARED_COST', 'TOLL_FREE', 'UAN', 'PERSONAL_NUMBER'],
    mobile  : ['MOBILE', 'VOICEMAIL'],
};

export const LABEL_MOBILE_LINES = Object.freeze({
    advancedMobile: 'advancedMobile',
});
