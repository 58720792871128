import { useTranslation, store, onAndroidDevice, onIOSDevice } from '@sewan/core';
import { useCurrentBreakpoint } from '@sewan-selfcare/responsive-system';
import RadioButton, { RadioButtonGroup } from '@ui-kit/radio-button';
import Typography from '@ui-kit/typography';
import React from 'react';
import {
    Wrapper,
    Banner,
    WrapperLicense,
    WrapperLogo,
    WrapperWebexCard,
    WrapperWebexDescription,
    StyledLogo,
    WrapperDesktopLogo,
} from './styles';

import AndroidLogo from '#assets/images/logo-app-android.svg';
import AppleLogo from '#assets/images/logo-app-ios-black.svg';
import MicrosoftLogo from '#assets/images/logo-app-microsoft-black.svg';
import WebexBanner from '#assets/images/webex_banner.png';
import { URL_WEBEX } from '#constants';
import { TEST_IDS } from '#testIds';

const Webex = () => {
    const { t } = useTranslation('common');
    const webex = store.hostedTelephony.getWebex();

    const { currentBreakpoint } = useCurrentBreakpoint();
    const isMobile = [0, 1, 2].includes(currentBreakpoint);

    return (
        <Wrapper>
            <Typography
                weight="semiBold"
                size="xl"
                color="greys.800"
                testId={TEST_IDS.webex.typography.title}
            >

                {t('softphones.webex.title')}
            </Typography>
            <WrapperWebexCard>
                { isMobile && <Banner img={WebexBanner} /> }

                <WrapperWebexDescription>
                    <Typography
                        weight="semiBold"
                        size="lg"
                        color="greys.800"
                        testId={TEST_IDS.webex.typography.description}
                    >
                        {t('softphones.webex.page.description')}
                    </Typography>
                    <Typography
                        weight="medium"
                        size="sm"
                        color="greys.800"
                        testId={TEST_IDS.webex.typography.subDescription}
                    >
                        {t('softphones.webex.page.subDescription')}
                    </Typography>
                    <WrapperLicense
                        flexDirection="column"
                        alignItems="start"
                    >
                        <Typography
                            weight="regular"
                            size="md"
                            color="greys.800"
                            testId={TEST_IDS.webex.typography.license.title}
                        >
                            {t('softphones.webex.page.license.title')}
                        </Typography>
                        <RadioButtonGroup
                            orientation="vertical"
                            value={webex.license.toUpperCase()}
                            testId={TEST_IDS.webex.radioButtonGroup}
                        >
                            <RadioButton
                                label={t('softphones.webex.page.license.basic')}
                                testIds={{
                                    radio: TEST_IDS.webex.radioButton.basic,
                                }}
                                value="BASIC"
                                isDisabled
                            />
                            <RadioButton
                                label={t('softphones.webex.page.license.standard')}
                                testIds={{
                                    radio: TEST_IDS.webex.radioButton.standard,
                                }}
                                value="STANDARD"
                                isDisabled
                            />
                            <RadioButton
                                label={t('softphones.webex.page.license.premium')}
                                testIds={{
                                    radio: TEST_IDS.webex.radioButton.premium,
                                }}
                                value="PREMIUM"
                                isDisabled
                            />
                        </RadioButtonGroup>
                        <Typography
                            weight="medium"
                            size="sm"
                            color="greys.600"
                            testId={TEST_IDS.webex.typography.license.description}
                        >
                            {t('softphones.webex.page.license.description')}
                        </Typography>
                    </WrapperLicense>
                    <WrapperLogo
                        flexDirection="column"
                        alignItems="start"
                    >
                        <Typography
                            weight="regular"
                            size="md"
                            color="greys.800"
                            testId={TEST_IDS.webex.typography.downloadDescription}
                        >
                            {t('softphones.webex.page.downloadDescription')}
                        </Typography>
                        <WrapperDesktopLogo>
                            {isMobile && onAndroidDevice() && (
                                <StyledLogo
                                    img={AndroidLogo}
                                    width="40px"
                                    height="40px"
                                    onClick={() => window.open(URL_WEBEX.ANDROID, '_blank')}
                                />
                            )}
                            {isMobile && onIOSDevice() && (
                                <StyledLogo
                                    img={AppleLogo}
                                    width="40px"
                                    height="40px"
                                    onClick={() => window.open(URL_WEBEX.IOS, '_blank')}
                                />
                            )}
                            {
                                !isMobile && (
                                    <>
                                        <StyledLogo
                                            img={MicrosoftLogo}
                                            width="40px"
                                            height="40px"
                                            onClick={() => window.open(URL_WEBEX.MICROSOFT, '_blank')}
                                        />
                                        <StyledLogo
                                            img={AppleLogo}
                                            width="40px"
                                            height="40px"
                                            onClick={() => window.open(URL_WEBEX.MAC, '_blank')}
                                        />
                                    </>
                                )
                            }
                        </WrapperDesktopLogo>
                    </WrapperLogo>
                </WrapperWebexDescription>
                { !isMobile && <Banner img={WebexBanner} /> }
            </WrapperWebexCard>
        </Wrapper>
    );
};

export default Webex;
