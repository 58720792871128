export const rootVoicemailPath = 'voicemails';

/**
 * ENUM for application route names
 */
export const AppsRouteName = {
    HOME                        : '',
    TELEPHONY_SETTINGS          : 'telephony-settings',
    DOCUMENTS                   : 'documents',
    CALL_LOGS                   : 'call-logs',
    CALL_FORWARDING             : 'call-forwarding',
    CONSUMPTION_TRACKING        : 'consumption-tracking',
    CONTACTS                    : 'contacts',
    VOICEMAILS_MESSAGES         : `${rootVoicemailPath}/messages`,
    VOICEMAILS_MOBILE_SETTINGS  : `${rootVoicemailPath}/mobile-settings`,
    VOICEMAILS_LANDLINE_SETTINGS: `${rootVoicemailPath}/landline-settings`,
    PROFILE                     : 'profile',
    LOGIN                       : 'login',
    WEBEX                       : 'softphone/webex',
};

export const SEWAN_BASENAME_URL = 'sewan.fr';
