export const FORWARD_TYPE = Object.freeze({
    fork         : 'fork',
    noconnection : 'noconnection',
    noanswer     : 'noanswer',
    onbusy       : 'onbusy',
    unconditional: 'unconditional',
    scheduled    : 'scheduled',
    waiting      : 'waiting',
});

export const FORWARD_TO = Object.freeze({
    phonenumber: 'phonenumber',
    voicemail  : 'voicemail',
});
