import styled, { th } from '@xstyled/styled-components';
import { NavLink } from 'react-router-dom';

export const SWrapper = styled.div`
    height: 64px;
    width: 100%;
    background-color: ${th('newTheme.colors.white')};
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${th('newTheme.colors.greys.200')};
    justify-content: space-between;
`;

export const STitle = styled.div`
    display: flex;
    align-items: center;
    width: 280px;
    padding: 0 12px;
    font-size: 18px;
    font-weight: 600;
`;

export const SLeftSide = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
`;

export const SBurgerButton = styled.div`
    margin-right: 24px;
`;

export const SSelfcareLogo = styled.div`
    @media (min-width: ${th('breakpoints.lg')}) {
        display: initial;
        margin-right: 12px;
    }
`;

export const SNavLinkLink = styled(NavLink)`
    color: ${th('newTheme.colors.greys.800')};
    text-decoration: none;
`;

export const SMobileHeaderWrapper = styled.div`
    @media (min-width: ${th('breakpoints.lg')}) {
        padding-left: 12px;
    }
    padding-left: ${({ hasBurger }) => (hasBurger ? '12px' : '28px')};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SIconSelfcare = styled.img`
    max-height: 32px;
`;
