import { AppsRouteName, useTranslation, setSearchHistory } from '@sewan/core';
import { WebexLogo } from '@ui-kit/logo';
import Typography from '@ui-kit/typography';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useFilteredConfigRoutes } from '../../../../hooks';
import { GridThirdPartyMenuItemLogo, GridThirdPartyMenuItem } from '../../style';

import { TEST_IDS } from '#testIds';

const WebexMenuItem = () => {
    const { t } = useTranslation('common');
    const history = useHistory();

    const location = useLocation();
    const { hasHostedTelephony, hasFeatureFlag } = useFilteredConfigRoutes(true);
    const { pathname } = location;

    return (
        <GridThirdPartyMenuItem
            isActive={pathname === `/${AppsRouteName.WEBEX}`}
            onClick={() => {
                history.push({
                    pathname: `/${AppsRouteName.WEBEX}`,
                    search  : setSearchHistory(hasFeatureFlag && hasHostedTelephony),
                });
            }}
        >
            <GridThirdPartyMenuItemLogo>
                <WebexLogo height={48} width={48} />
            </GridThirdPartyMenuItemLogo>
            <div
                style={{
                    gridArea: 'service',
                }}
            >
                <Typography
                    color="primary"
                    size="xs"
                    weight="semiBold"
                    testId={TEST_IDS.webexMenuItem.typography.service}
                >
                    {t('softphones.webex.menu.service')}
                </Typography>
            </div>
            <div
                style={{
                    gridArea: 'title',
                }}
            >
                <Typography
                    color="greys.800"
                    size="md"
                    weight="medium"
                    testId={TEST_IDS.webexMenuItem.typography.title}
                >
                    {t('softphones.webex.title')}
                </Typography>
            </div>
            <div
                style={{
                    gridArea: 'description',
                }}
            >
                <Typography
                    color="greys.600"
                    size="xs"
                    weight="regular"
                    testId={TEST_IDS.webexMenuItem.typography.description}
                >
                    {t('softphones.webex.menu.description')}
                </Typography>
            </div>
        </GridThirdPartyMenuItem>
    );
};

export default WebexMenuItem;
